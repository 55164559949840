define("zipbooks/controllers/main/time-and-pay", ["exports", "zipbooks/utils/array", "ember-data"], function (_exports, _array, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend({
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    queryParams: ['user_ids', 'time_period', 'user_kind_filter'],
    user_ids: '',
    pay_period: null,
    user_kind_filter: 'all',
    summaryParams: function summaryParams() {
      var query = {
        filter: {}
      };

      if (this.user_ids) {
        query.filter.user_ids = this.user_ids.split(',');
      }

      if (this.user_kind_filter) {
        query.filter.kind = this.user_kind_filter;
      }

      if (this.pay_period) {
        query.filter.pay_period = this.pay_period;
      } else {
        query.filter.pay_period = this.model.payPeriods.lastObject.period;
      }

      return $.param(query);
    },
    summaryModel: Ember.computed('user_ids', 'pay_period', 'user_kind_filter', '_invalidations', function () {
      return _emberData.default.PromiseObject.create({
        promise: this.client.GET("payroll/user-summary?".concat(this.summaryParams())).then(function (json) {
          return {
            data: json.data.map(function (attributes) {
              return Ember.Object.create(attributes);
            }),
            meta: json.meta
          };
        })
      });
    }),
    filteredUser: Ember.computed('model.users', 'user_ids', function () {
      var _this = this;

      return this.model.users.find(function (u) {
        return u.id === _this.user_ids;
      });
    }),
    parsedPayPeriods: Ember.computed('model.payPeriods', function () {
      return this.model.payPeriods.reverse().map(function (pp) {
        var _pp$period$split = pp.period.split('..'),
            _pp$period$split2 = _slicedToArray(_pp$period$split, 2),
            sd = _pp$period$split2[0],
            ed = _pp$period$split2[1];

        return {
          id: pp.period,
          payrollProcessed: pp.payroll_processed,
          parsed: {
            start: sd,
            end: ed
          },
          formatted: "".concat(moment(sd).format('MMM D'), " \u2013 ").concat(moment(ed).format('MMM D, YYYY'))
        };
      });
    }),
    selectedPayPeriod: Ember.computed('parsedPayPeriods', 'pay_period', function () {
      var _this2 = this;

      var selectedPayPeriod = this.parsedPayPeriods.find(function (pp) {
        return pp.id === _this2.pay_period;
      });

      if (!selectedPayPeriod) {
        this.set('pay_period', this.model.payPeriods.lastObject.period);
        return this.parsedPayPeriods.find(function (pp) {
          return pp.id === _this2.pay_period;
        });
      }

      return selectedPayPeriod;
    }),
    previousPayPeriod: Ember.computed('payPeriods', 'pay_period', function () {
      var idx = this.model.payPeriods.map(function (pp) {
        return pp.period;
      }).indexOf(this.pay_period);
      return idx > -1 && idx < this.model.payPeriods.length - 1 ? this.model.payPeriods[idx + 1].period : null;
    }),
    nextPayPeriod: Ember.computed('payPeriods', 'pay_period', function () {
      var idx = this.model.payPeriods.map(function (pp) {
        return pp.period;
      }).indexOf(this.pay_period);
      return idx > 0 ? this.model.payPeriods[idx - 1].period : null;
    }),
    groupedByUser: Ember.computed('summaryModel.data', function () {
      if (this.get('summaryModel.data')) {
        return (0, _array.groupBy)(this.get('summaryModel.data'), function (v) {
          return v['user-id'];
        });
      } else {
        return {};
      }
    }),
    _observer: Ember.observer('model.users.@each', 'groupedByUser', 'summaryModel.meta.gusto-payroll-data', function () {
      var _this3 = this;

      if (Object.keys(this.groupedByUser).length > 0) {
        this.set('isRefreshing', false);
        var users = this.getWithDefault('model.users', []).filter(function (u) {
          var isIncluded = !!_this3.groupedByUser[u.id];

          var hasAnyTime = isIncluded && _this3.groupedByUser[u.id].any(function (e) {
            return e.seconds;
          });

          return isIncluded && (hasAnyTime || !u.isArchived);
        }).map(function (u) {
          var gustoPayrollData = _this3.get('summaryModel.meta.gusto-payroll-data');

          if (gustoPayrollData) {
            var data = gustoPayrollData.find(function (d) {
              return d['employee-id'] === u.integrationObject.get('externalId');
            });

            if (data) {
              u.set('gustoHourly', true);
              u.set('gustoSeconds', data.seconds);
              u.set('syncRangeMax', data.seconds + 60);
              u.set('syncRangeMin', data.seconds - 60);
            }

            u.set('syncedSeconds', _this3.groupedByUser[u.id].reduce(function (acc, d) {
              return acc + d['gusto-synced-duration'];
            }, 0));
          }

          return u;
        });
        this.set('usersWithData', users);
      }
    }),
    actions: {
      filterByUser: function filterByUser(user) {
        this.set('user_ids', user && user.id || '');
      },
      markAllAsPaid: function markAllAsPaid() {
        var _this4 = this;

        var user_ids = this.user_ids.split(',').filter(function (id) {
          return !Ember.isBlank(id);
        });
        var names = this.model.users.filter(function (u) {
          return user_ids.includes(u.id);
        }).map(function (u) {
          return (u.get('identity.firstName') || u.get('identity.email')) + "'s";
        });
        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
          title: "This will mark all ".concat(names.length > 0 ? 'of ' + names.join(', ') : '', " time entries from ").concat(this.selectedPayPeriod.formatted, " as paid.").stripHTML(),
          confirmButton: {
            title: 'Yes, mark all as paid',
            class: 'btn-blue'
          },
          cancelButton: {
            title: 'No, cancel',
            class: 'btn-white'
          }
        }, {
          confirm: function confirm() {
            var params = {
              filter: {
                date_range: _this4.pay_period
              }
            };

            if (user_ids.length > 0) {
              params.filter.user_ids = user_ids;
            }

            _this4.client.PATCH('payroll', 'mark-as-paid', params).then(function () {
              _this4.incrementProperty('_invalidations');
            });
          },
          cancel: function cancel() {}
        });
      },
      syncAll: function syncAll() {
        var _this5 = this;

        var user_ids = this.user_ids.split(',').filter(function (id) {
          return !Ember.isBlank(id);
        });
        var params = {
          filter: {
            date_range: this.pay_period
          }
        };

        if (user_ids.length > 0) {
          params.filter.user_ids = user_ids;
        }

        return this.client.PATCH('integrations/gusto', 'payroll', params).then(function () {
          return _this5.incrementProperty('_invalidations');
        }, function () {
          return _this5.overlay.showBanner('error', 'Gusto was unable to process the request. Please try again.');
        });
      },
      markUsersTimeAsPaid: function markUsersTimeAsPaid(user) {
        var _this6 = this;

        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
          title: "This will mark all of ".concat(user.get('identity.firstName') || user.get('identity.email'), "'s time entries as paid.").stripHTML(),
          confirmButton: {
            title: 'Yes, mark all as paid',
            class: 'btn-blue'
          },
          cancelButton: {
            title: 'No, cancel',
            class: 'btn-white'
          }
        }, {
          confirm: function confirm() {
            _this6.client.PATCH('payroll', 'mark-as-paid', {
              filter: {
                user_ids: [user.id]
              }
            }).then(function () {
              _this6.incrementProperty('_invalidations');
            });
          },
          cancel: function cancel() {}
        });
      },
      markUsersPeriodAsPaid: function markUsersPeriodAsPaid(user, payPeriod) {
        var _this7 = this;

        var params = {
          filter: {
            user_ids: [user.id],
            date_range: payPeriod
          }
        };
        this.client.PATCH('payroll', 'mark-as-paid', params).then(function () {
          _this7.incrementProperty('_invalidations');
        });
      },
      syncUsersPeriod: function syncUsersPeriod(user, payPeriod) {
        var _this8 = this;

        var params = {
          filter: {
            user_ids: [user.id],
            date_range: payPeriod
          }
        };
        return this.client.PATCH('integrations/gusto', 'payroll', params).then(function () {
          return _this8.incrementProperty('_invalidations');
        }, function () {
          return _this8.overlay.showBanner('error', 'Gusto was unable to process the request. Please try again.');
        });
      },
      markDayAsPaid: function markDayAsPaid(user, date, event) {
        var _this9 = this;

        event.preventDefault();
        event.stopPropagation();
        var params = {
          filter: {
            user_ids: [user.id],
            date_range: "".concat(date, "..").concat(date)
          }
        };
        this.client.PATCH('payroll', 'mark-as-paid', params).then(function () {
          _this9.get('summaryModel.data').filter(function (d) {
            return d['user-id'] === user.id && d.date === date;
          }).forEach(function (data) {
            data.set('percent-paid', 1);
          });

          _this9.client.GET("payroll/user-summary?".concat(_this9.summaryParams())).then(function (json) {
            _this9.set('summaryModel.meta', json.meta);
          });
        });
      },
      viewTimeEntries: function viewTimeEntries(day, userId) {
        this.transitionToRoute('main.time-entries', {
          queryParams: {
            start_date: day.date,
            end_date: day.date,
            user_id: userId
          }
        });
      },
      linkUser: function linkUser(user) {
        var _this10 = this;

        this.overlay.showDialog('overlays/dialogs/link-user-to-gusto', 'Gusto Setup', {
          customHeader: true,
          userId: user.id,
          refresh: function refresh() {
            return _this10.incrementProperty('_invalidations');
          }
        });
      },
      launchOnboarding: function launchOnboarding() {
        this.overlay.showDialog('overlays/dialogs/gusto-onboarding', 'Gusto Setup', {
          customHeader: true,
          gustoModel: this.model.gusto
        });
      }
    }
  });

  _exports.default = _default;
});
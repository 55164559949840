define("zipbooks/components/overlays/dialogs/link-user-to-gusto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    client: Ember.inject.service(),
    store: Ember.inject.service(),
    loading: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.setup();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$().closest('.modal-dialog').addClass('modal-dialog-wider');
    },
    setup: function setup() {
      var _this = this;

      this.store.findRecord('user', this.model.userId).then(function (user) {
        user.set('needsName', Ember.isBlank(user.identity.get('firstName')) || Ember.isBlank(user.identity.get('lastName')));

        _this.set('userModel', {
          operation: Ember.Object.create({}),
          user: user
        });

        _this.client.GET('integrations/gusto/employees').then(function (resp) {
          var operationOptions = [{
            value: Ember.Object.create({
              operationKey: 'add-user'
            })
          }, {
            value: Ember.Object.create({
              operationKey: 'dont-manage'
            })
          }].concat(resp.map(function (employee) {
            return {
              value: Ember.Object.create({
                operationKey: 'associate',
                employee: employee
              })
            };
          }));

          _this.set('operationOptions', operationOptions);

          _this.set('loading', false);
        });
      });
    },
    actions: {
      associateUsers: function associateUsers() {
        var _this2 = this;

        this.set('buttonState', 'saving');
        this.get('reference.userAssociator').save().then(function () {
          return _this2.nextStep('associate-users');
        }, function () {
          _this2.get('reference.userAssociator').fetchEmployeesAndUsers();

          _this2.set('buttonState', 'invalid');
        });
      },
      finish: function finish() {
        var _this3 = this;

        this.set('buttonState', 'saving');
        var user = this.userModel.user;

        if (this.userModel.operation.operationKey === 'associate') {
          var object = this.store.createRecord('integration-object', {
            externalId: this.userModel.operation.employee.id,
            externalSource: 'Gusto',
            enableSync: false
          });
          if (!user.title) user.set('title', this.userModel.operation.employee.title);
          if (!user.hourlyCost) user.set('hourlyCost', this.userModel.operation.employee.hourly_cost);
          user.set('integrationObject', object);
          user.save().then(function () {
            _this3.model.refresh();

            _this3.set('buttonState', 'loaded');

            _this3.close();
          });
        } else if (this.userModel.operation.operationKey === 'add-user') {
          if (Ember.isBlank(user.identity.get('firstName')) || Ember.isBlank(user.identity.get('lastName'))) {
            if (Ember.isBlank(user.identity.get('firstName'))) {
              user.identity.set('errors.firstName', [{
                message: 'First name is required.'
              }]);
            } else {
              user.identity.set('errors.firstName', []);
            }

            if (Ember.isBlank(user.identity.get('lastName'))) {
              user.identity.set('errors.lastName', [{
                message: 'Last name is required.'
              }]);
            } else {
              user.identity.set('errors.lastName', []);
            }

            this.set('buttonState', 'invalid');
            return;
          }

          this.client.POST('integrations/gusto/employees', null, {
            users: [{
              id: user.id,
              'first-name': user.identity.get('firstName'),
              'last-name': user.identity.get('lastName')
            }]
          }).then(function () {
            _this3.model.refresh();

            _this3.set('buttonState', 'loaded');

            _this3.close();
          });
        } else {
          this.close();
        }
      },
      close: function close() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});
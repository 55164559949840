define("zipbooks/components/overlays/dialogs/gusto-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    client: Ember.inject.service(),
    steps: ['choose-company', 'associate-users', 'unassigned-users', 'assign-permissions'],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('reference', {
        userAssociator: null
      });

      if (this.model.nextStep) {
        this.set('step', this.model.nextStep);
      } else {
        this.client.GET('integrations/gusto/next-step').then(function (resp) {
          var nextStep = resp.next_step;

          if (nextStep === 'choose-company') {
            _this.client.GET('integrations/gusto/companies').then(function (resp) {
              _this.set('companies', resp.companies);

              _this.set('companyModel', {
                company: resp.companies.firstObject
              });

              if (resp.companies.length === 1) {
                _this.chooseCompany();
              } else {
                _this.set('step', 'choose-company');
              }
            });
          } else {
            _this.set('step', nextStep);
          }
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$().closest('.modal-dialog').addClass('modal-dialog-wider');
    },
    percentCompleted: Ember.computed('step', function () {
      var idx = this.steps.indexOf(this.step);
      return idx / (this.get('steps.length') - 1) * 100;
    }),
    chooseCompany: function chooseCompany() {
      var _this2 = this;

      this.set('buttonState', 'saving');
      this.model.gustoModel.set('externalCompanyId', this.companyModel.company.id);
      this.model.gustoModel.save().then(function () {
        return _this2.nextStep('choose-company');
      });
    },
    nextStep: function nextStep() {
      var _this3 = this;

      var currentStep = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this.client.GET('integrations/gusto/next-step', "?current_step=".concat(currentStep)).then(function (resp) {
        _this3.set('buttonState', 'loaded');

        _this3.set('step', resp.next_step);
      });
    },
    actions: {
      backToChooseCompany: function backToChooseCompany() {
        var _this4 = this;

        this.client.GET('integrations/gusto/companies').then(function (resp) {
          _this4.set('companies', resp.companies);

          _this4.set('companyModel', _this4.companyModel || {
            company: resp.companies.firstObject
          });

          _this4.set('step', 'choose-company');
        });
      },
      chooseCompany: function chooseCompany() {
        this.chooseCompany();
      },
      associateUsers: function associateUsers() {
        var _this5 = this;

        this.set('buttonState', 'saving');
        this.get('reference.userAssociator').save().then(function () {
          return _this5.nextStep('associate-users');
        }, function (error) {
          if (error !== 'You must choose an option for each employee.') {
            _this5.get('reference.userAssociator').fetchEmployeesAndUsers();
          }

          _this5.set('buttonState', 'invalid');
        });
      },
      unassignedUsers: function unassignedUsers() {
        var _this6 = this;

        this.set('buttonState', 'saving');
        this.get('reference.userAssociator').save().then(function () {
          _this6.set('buttonState', 'loaded');

          _this6.set('step', 'assign-permissions');
        }, function () {
          _this6.set('buttonState', 'invalid');
        });
      },
      assignPermissions: function assignPermissions() {
        var _this7 = this;

        this.set('buttonState', 'saving');
        this.get('reference.userAssociator').save().then(function () {
          _this7.model.gustoModel.set('finishedOnboarding', true);

          _this7.model.gustoModel.set('hasNewUsers', false);

          _this7.model.gustoModel.save().then(function () {
            _this7.router.transitionTo('main.time-and-pay');

            _this7.close();
          });
        });
      },
      close: function close() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});